import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
//import sapin from '../../assets/pictures/sapin.jpg'
//import sapin2 from '../../assets/pictures/sapin2.jpg'
import me from '../../assets/pictures/me.jpg'
import me2 from '../../assets/pictures/me2.jpg'
import walle from '../../assets/pictures/wall-e.jpg'
import walle2 from '../../assets/pictures/wall-e2.jpg'
import nature from '../../assets/pictures/nature.jpg'
import nature2 from '../../assets/pictures/nature2.jpg'
import snowflakes from '../../assets/pictures/snowflakes.png'
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// styles
import './m-banner.css'
import './d-banner.css'

const Banner = () => {

	const [currentState, setCurrentState] = useState(0)

	useEffect(()=> {
		const timer = setTimeout(() => {
			if (currentState === 2) {
				setCurrentState(0)
			}
			else {
				setCurrentState(currentState +1)
			}
		}, 5000)
		return () => clearTimeout(timer)
	}, [currentState] )

	const slideArray = [
		{	
			"img": `${me}`
		},
		{
			"img": `${walle}`
		},
		{
			"img": `${nature}`
		}
	]

	const slideArray2 = [
		{	
			"img": `${me2}`
		},
		{
			"img": `${walle2}`
		},
		{
			"img": `${nature2}`
		}
	]

	return (
		<>
			<div className='banner__pub'>
				<p>{/*<span>OPÉRATION SNOW, PROFITEZ DE 30% DE REMISE IMMÉDIATE SUR TOUS VOS PROJETS DU 1/12/2023 AU 5/01/2024&nbsp;</span>&bull;&nbsp;*/}APPLICATION WEB & MOBILE &bull; API REST &bull; SITE E-COMMERCE &bull; WEB DESIGN &bull;&nbsp;{/*<span>OPÉRATION SNOW, PROFITEZ DE 30% DE REMISE IMMÉDIATE SUR TOUS VOS PROJETS DU 1/12/2023 AU 5/01/2024&nbsp;</span>&bull;&nbsp;*/}ACCESSIBILITÉ &bull; SEO &bull; INTÉGRATION WEB &bull; CONSULTING &bull; RÉDACTION WEB & ILLUSTRATION WEB &bull; INFOGRAPHIE &bull; ANIMATION &bull;&nbsp;</p>
				<p>{/*<span>OPÉRATION SNOW, PROFITEZ DE 30% DE REMISE IMMÉDIATE SUR TOUS VOS PROJETS DU 1/12/2023 AU 5/01/2024&nbsp;</span>&bull;&nbsp;*/}APPLICATION WEB & MOBILE &bull; API REST &bull; SITE E-COMMERCE &bull; WEB DESIGN &bull;&nbsp;{/*<span>OPÉRATION SNOW, PROFITEZ DE 30% DE REMISE IMMÉDIATE SUR TOUS VOS PROJETS DU 1/12/2023 AU 5/01/2024&nbsp;</span>&bull;&nbsp;*/}ACCESSIBILITÉ &bull; SEO &bull; INTÉGRATION WEB &bull; CONSULTING &bull; RÉDACTION WEB & ILLUSTRATION WEB &bull; INFOGRAPHIE &bull; ANIMATION &bull;&nbsp;</p>
			</div>
			<section id='banner' className='banner'>
				<h1 className='banner__title'>
					<span lang="en" className='banner__title-v'>V</span>
					<span lang="en" className='banner__title-o'>O</span>
					tre
					<br/>
					<span lang="en" className='banner__title-w'>W</span>
					eb
					<br/>
					<span lang="en" className='banner__title-d'>D</span>
					ev.
				</h1>
				<Link  className='banner__button' to='/'>
					<FontAwesomeIcon 
						className='banner__button__btn' 
						icon={faCirclePlay} 
					/>
					<p className='banner__button__text'>
						Tout savoir
						<br/>
						en 5 minutes
					</p>
				</Link>
				<h2 className='banner__subtitle'>Simplement Votre !</h2>
				{/*<div>
					<img 
						className='banner__picture banner__picture--in' 
						src={sapin} 
						width='720px' 
						height='1342px' 
						fetchpriority='high' 
						alt='sapin' 
					/>
				</div>
				<div>
					<img 
						className='banner__picture2 banner__picture--in' 
						src={sapin2} 
						width='1351px' 
						height='1342px' 
						fetchpriority='high'  
						alt='sapin' 
					/>
				</div>*/}
				<div> 
					{slideArray.map((items, index) => (
						<div key={index}>
							<img className={index === currentState ? 'banner__picture banner__picture--in' : 'banner__picture banner__picture--out'}
							src={items.img}
							width='720px' 
							height='1342px' 
							fetchpriority='high' 
							alt='slide' />
						</div>
					))}
				</div>
				<div> 
					{slideArray2.map((items, index) => (
						<div key={index}>
							<img className={index === currentState ? 'banner__picture2 banner__picture--in' : 'banner__picture2 banner__picture--out'}
							src={items.img}
							width='1351px' 
							height='1342px'  
							fetchpriority='high'
							alt='slide' />
						</div>
					))}
				</div>
				<div className='banner__info'>
					<p>
					La transition numérique
					<br/> 
					<span>en toute souplesse et toute sérénité</span>
					<br/><br/>
					Nos prestations
					<br/>
					<span>haut de gamme à la portée de toutes les bourses</span>
					<br/><br/>
					Nos technologies
					<br/> 
					<span>REACT JS - NEST JS - NODE JS<br/>EXPRESS - MONGO DB</span>
					<br/><br/>
					Nos valeurs
					<br/>
					<span>Intégrité, diversité et ouverture d'esprit</span>
					</p>
				</div>
				{/*<div className='snow-container'>
					<img className='snowflakes' src={snowflakes} width='1351px' height='1342px' high='90' alt='flocons' />
					<img className='snowflakes' src={snowflakes} width='1351px' height='1342px' high='90' alt='flocons' />
				</div>*/}
			</section>
		</>
	)
}
 
export default Banner