import { useEffect } from 'react'
import Background from '../../public/components/Background'
import Header from '../../public/components/Header'
import Banner from '../../public/components/Banner'
import About from '../../public/components/About'
import Vowd from '../../public/components/Vowd'
import Vowd2 from '../../public/components/Vowd2'
import Vowd3 from '../../public/components/Vowd3'
import Vowd4 from '../../public/components/Vowd4'
import LetsTalk from '../../public/components/LetsTalk'
import Futur from '../../public/components/Futur'
import Services from '../../public/components/Services'
import Portfolio from '../../public/components/Portfolio'
import Contact from '../../public/components/Contact'
import Partners from '../../public/components/Partners'
import Footer from '../../public/components/Footer'
import Alert from '../../public/components/Alert'
import { Helmet } from 'react-helmet-async' 

const PublicContainer = () => {

	useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

	return (
		<>
			<Helmet>
				<title>vowd.fr</title>
				<meta name="description"
					content="vowd.fr est une agence de communication spécialisée dans les projets web et mobile basée à Lyon. Le concept de la société vowd.fr est d’amener la stratégie commerciale des entrepreneurs et auto-entrepreneurs vers des solutions adaptées à leur besoin en matière numérique et leur moyen de financement. vowd.fr, propose des prestations numériques de qualité professionnelle casi haut de gamme à la portée de toutes les bourses. Les partenaires de vowd, techniciens et ingénieurs du IT sont experts dans la création d’application web ou mobile, API REST, site e-commerce ainsi que dans l’optimisation de site en matière d’accessibilité et de SEO, dans le web design, l’intégration de maquette, la rédaction de contenu web et l’infographie sous toutes ses formes ainsi que de l'animation 3d. vowd.fr fournit des rapports d’analyse et d’optimisation pour tous les projets numériques qui s’y prêtent." />
				<link rel="canonical" href="https://www.vowd.fr/" />
			</Helmet>
			<main>
				<Header />
				<Banner />
				<About />
				<Vowd />
				<LetsTalk />
				<Futur />
				<Vowd2 />
				<Services />
				<Portfolio />
				<Vowd3 />
				<Contact />
				<Partners />
				<Vowd4 />
				<Footer />
				<Background />
			</main>
			<Alert />
		</>
	) 
}
 
export default PublicContainer