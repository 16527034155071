import { useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin} from 'gsap/ScrollToPlugin'
import ideo from '../../assets/pictures/ideo.jpg'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

const Vowd4 = () => {

    const section4InTop = (elem, delay, duration) => {
        gsap.fromTo(
            elem,
            {
                transform: "translateX(-150vw)",
            },
            {
                transform: "translateX(0)" ,
                delay: delay || .5,
                duration: duration || .5,
                scrollTrigger: {
                    trigger: elem,
                    start: "top center",
                    end: "bottom center"
                }
            }
        )
    }

    useEffect(() => {
        section4InTop('#vowd4__filtre')
    }, [])

    const onEnter4 = () => {
        gsap.to('#vowd4__filtre', {
            transform: "translateX(0)",
        })
    }

    const onLeave4 = ({ currentTarget }) => {
        gsap.to(currentTarget, {
            transform: "translateX(-150vw)",
        })
    }

    return (
        <section className='vowd'>
            <img 
                className='vowd__img' 
                src={ideo} 
                width='1500px' 
                height='1000px'
                loading='lazy'
                alt='people' 
                onMouseEnter={onEnter4}
            />
            <div 
                id='vowd4__filtre' 
                className='vowd__filtre'
                onMouseLeave={onLeave4}
            >
                <p className='vowd__filtre__text'>
                    Nos valeurs : Intégrité, diversité et ouverture d'esprit
                </p>
            </div>
        </section>
    )
}

export default Vowd4