import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom' 
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin} from 'gsap/ScrollToPlugin'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import signature from '../../assets/pictures/signature.png'


// styles
import './m-alert.css'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

const Alert = () => {

    const [alertClosed, setAlertClosed] = useState(true)

    const slideAlert = (elem, delay, duration) => {
        gsap.fromTo(
            elem,
            {
                opacity: 0,
                y: -200,
            },
            {
                opacity: 1,
                y: 0,
                delay: delay || 2,
                duration: duration || 1,
                scrollTrigger: {
                    trigger: elem,
                    start: "top center",
                    end: "bottom center"
                }
            }
        )
    }

    useEffect(() => {
        slideAlert('#alert')
    }, [])

    return (
        <div 
            id='alert' 
            className='alert-container'
            style={
                alertClosed ?
                {display: 'block'} : 
                {display: 'none'}
            }
        >
            <div 
                onClick={() => setAlertClosed(!alertClosed)}
                className='alert-container__closed' 
            >
                <FontAwesomeIcon icon={faXmark} />
            </div>
            <header>
                <h1>BIENVENUE</h1>
            </header>
            <p>
                Quel que soit votre projet
                <br/>
                <br/>
                <strong>
                    <span>GRAPHISME - WEB - MOBILE - DIGITAL MARKETNG - ANIMATION
                    <br/>
                    <br/>
                    VOWD.FR VOUS ACCOMPAGNE
                    </span>
                </strong>
                <br/>
                <br/>
            </p>
            <div className='alert-container__signature'>Aaron Divioka, fondateur</div>
            <br/>
            <img    
                className='signature' 
                src={signature}
                width='298px' 
                height='54px' 
                loading='lazy' 
                alt='signature de Aaron'/>
            <br/>
            <br/>
            <br/>
            <br/>
            <aside className='alert-container__aside'>
                <header className='alert-container__aside__header'>
                    <h1 lang='en'>
                        Actualités 2024
                    </h1>
                    <p id='alert-container__aside__info'>
                        Vowd.fr vous présente
                        <br/>
                        sa dernière réalisation
                        <br/><br/>
                        <Link to='https://www.mutuact.fr' className='alert-container__aside__info__link'>
                        <i>c'est par ici !</i>
                        </Link>
                    </p>
                </header>
            </aside>
        </div>
    )
}

export default Alert