import { useNavigate } from 'react-router-dom'
import ReactPlayer from 'react-player'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import vowd from '../../assets/brands/logo-vowd.png'

// video
import letsTalkAboutVDO from '../../assets/video/letsTalkAboutVDO.mp4'

// styles
import './m-videovowd.css'
import './d-videovowd.css'

const VideoVowd = () => {
  
  window.onload = () => {
		localStorage.removeItem('token')
    window.scrollTo(0, 0)
	}

  const navigate = useNavigate()

  const backToSite = () => {
    navigate('/')
  }

  return (
    <div
      id='video-vowd' 
      className='video-vowd'>
        <FontAwesomeIcon
          onClick={backToSite}
          className='arrow-left'
          id='video-arrow-left'
          icon={faArrowLeft}
        />
        <h1>
            Video
        </h1>
        <div className='video-vowd__wrapper'>
            <ReactPlayer 
                className='video-vowd__wrapper__vdo'
                url={letsTalkAboutVDO}
                controls
                width='100%'
                height='100%'
            />
        </div>
        <img 
          className='video-vowd__logo-vowd'
          src={vowd}
          width='1096px' 
          height='984px'
          loading='lazy'
          alt='logo-vowd' 
        />
        <span lang="en" className='video-vowd__title-vowd'>
				VOWD.fr
			  </span>
        <span className='video-vowd__text'>conception web et communication digitale<br/>https://www.vowd.fr</span>
    </div>
  )
}

export default VideoVowd