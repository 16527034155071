// import { Link as CGU } from 'react-router-dom'
import { Link } from 'react-scroll'
import { useState } from 'react'
import vowd from '../../assets/brands/logo-vowd.png'
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// styles
import './m-header.css'

const Header = () => {

	const [isOpen, setIsOpen] = useState(false)

	const isOpenState = () => {
        setIsOpen(!isOpen)
    }

	return (
		<nav id='navbar' className={	
			isOpen ? 
			'navbar navbar-open':
			'navbar navbar-closed'}>
				
			<img 
				className='navbar__logo-vowd' 
				src={vowd} 
				width='1096px' 
				height='984px'
				loading='lazy'
				alt='logo-vowd' 
			/>
			<span lang="en" className='navbar__title-vowd'>
				VOWD.fr
			</span>
			{/*<CGU to='/CGU'>
				<p className='navbar__cgu'>C.G.U.</p>
			</CGU>*/}
			<FontAwesomeIcon 
				onClick={isOpenState} 
				className={
					isOpen ?
					'navbar__button-bars closed':
					'navbar__button-bars open'} 
				icon={faBars} 
			/>
			<FontAwesomeIcon 
				onClick={isOpenState}
				className={
					isOpen ?
					'navbar__button-x open':
					'navbar__button-x closed'} 
				icon={faXmark} 
			/>
			<menu className={
				isOpen ?
				'navbar__menu navbar__menu-open':
				'navbar__menu navbar__menu-closed'}>
				<div	
					onClick={isOpenState} 
					className='nav__menu__link nav__menu__link-zero'>
					Accueil
				</div>
				<Link to='about'>
					<div
						onClick={isOpenState}
						className='nav__menu__link nav__menu__link-one'>
						A propos
					</div>
				</Link>
				<Link to='futur'>
					<div 
						onClick={isOpenState}
						className='nav__menu__link nav__menu__link-two'>
						A portée de main
					</div>
				</Link>
				<Link to='letsTalk'>
					<div
						onClick={isOpenState}
						className='nav__menu__link nav__menu__link-hree'>
						Projet ? Parlons-en
					</div>
				</Link>
				<Link to='services'>
					<div
						onClick={isOpenState}
						className='nav__menu__link nav__menu__link-four'>
						Services de qualité
					</div>
				</Link>
				<Link to='portfolio'>
					<div
						onClick={isOpenState}
						className='nav__menu__link nav__menu__link-five'>
						Mes réalisations
					</div>
				</Link>
				<Link to='contact'>
					<div
						onClick={isOpenState}
						className='nav__menu__link nav__menu__link-six'>
						Contact France
					</div>
				</Link> 
			</menu> 
		</nav>
	)
}
 
export default Header