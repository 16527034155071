import { useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin} from 'gsap/ScrollToPlugin'
import techno from '../../assets/pictures/techno.jpg'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

const Vowd3 = () => {

    const section3InTop = (elem, delay, duration) => {
        gsap.fromTo(
            elem,
            {
                transform: "translateX(-150vw)",
            },
            {
                transform: "translateX(0)" ,
                delay: delay || .5,
                duration: duration || .5,
                scrollTrigger: {
                    trigger: elem,
                    start: "top center",
                    end: "bottom center"
                }
            }
        )
    }

    useEffect(() => {
        section3InTop('#vowd3__filtre')
    }, [])

    const onEnter3 = () => {
        gsap.to('#vowd3__filtre', {
            transform: "translateX(0)",
        })
    }

    const onLeave3 = ({ currentTarget }) => {
        gsap.to(currentTarget, {
            transform: "translateX(-150vw)",
        })
    }

    return (
        <section className='vowd'>
            <img 
                className='vowd__img' 
                src={techno} 
                width='1500px' 
                height='1000px'
                loading='lazy'
                alt='synaps' 
                onMouseEnter={onEnter3}
            />
            <div 
                id='vowd3__filtre' 
                className='vowd__filtre'
                onMouseLeave={onLeave3}
            >
                <p className='vowd__filtre__text'>Nos technologies : React js, Nest js, Node js, Express, Mongo DB</p>
            </div>
        </section>
    )
}

export default Vowd3