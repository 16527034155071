import { useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin} from 'gsap/ScrollToPlugin'
//import $ from 'jquery';
import pagesHeadersArray from '../../datas/pagesHeadersArray.json'
import PagesHeaders from '../../components/PagesHeaders'
import PubWarning from '../../components/PubWarning'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// styles
import './m-about.css'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

const About = () => {
    
    const aboutHeader = pagesHeadersArray.find(el => el.title === "1")
    const aboutHeaderArray = []
    aboutHeaderArray.push(aboutHeader)

    const slideInTop = (elem, delay, duration) => {
        gsap.fromTo(
            elem,
            {
                opacity: 0,
            },
            {
                opacity: 1,
                delay: delay || .4,
                duration: duration || 3,
                scrollTrigger: {
                    trigger: elem,
                    start: "top center",
                    end: "bottom center"
                }
            }
        )
    }

    useEffect(() => {
        slideInTop('#pub-warning')
    }, [])

	return (
		<section id='about' className='about'>
            <div id='pub-warning'>
                <PubWarning />
            </div>
            {aboutHeaderArray.map((item, index) => (
                <PagesHeaders 
                    key={index}
                    titleCol1={item.titleCol1}
                    titleCol2={item.titleCol2}
                    subTitle1={item.subTitle1}
                    subTitle2={item.subTitle2}
                    text1={item.text1} 
                    br1={item.br1 && <><br/><br/></>}
                    text2={item.text2}
                    icon1={
                        item.icon1 && 
                        <FontAwesomeIcon
                        className='about__icon' 
                        icon={faUsers} 
                        />
                    }
                />
            ))}
		</section>
	)
}

export default About