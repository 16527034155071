import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import {Routes, Route } from 'react-router-dom'
import PublicRouter from './public/PublicRouter'
import AdminRouter from './admin/AdminRouter'
import CGU from './public/components/CGU'
import Error from './_utils/Error'
import ScrollToTop from './functions/ScrollToTop'

// styles
import './styles/m-app.css'
import './styles/t-d-app.css'

const App = () => {
    return (
        <div>
            <HelmetProvider>
                <Routes>
                    <Route exact path='/*' element={ <PublicRouter /> } />
                    <Route path='/CGU/*' element={ <CGU /> } />
                    <Route path='/dashboard/*' element={ <AdminRouter /> } />
                    <Route path='*' element={ <Error /> } />
                </Routes>
            </HelmetProvider>
            <ScrollToTop />
        </div>
    ) 
}

export default App