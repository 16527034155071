import { useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin} from 'gsap/ScrollToPlugin'
import people2 from '../../assets/pictures/people2.jpg'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

const Vowd2 = () => {

    const section2InTop = (elem, delay, duration) => {
        gsap.fromTo(
            elem,
            {
                transform: "translateX(-150vw)",
            },
            {
                transform: "translateX(0)" ,
                delay: delay || .5,
                duration: duration || .5,
                scrollTrigger: {
                    trigger: elem,
                    start: "top center",
                    end: "bottom center"
                }
            }
        )
    }

    useEffect(() => {
        section2InTop('#vowd2__filtre')
    }, [])

    const onEnter2 = () => {
        gsap.to('#vowd2__filtre', {
            transform: "translateX(0)",
        })
    }

    const onLeave2 = ({ currentTarget }) => {
        gsap.to(currentTarget, {
            transform: "translateX(-150vw)",
        })
    }

    return (
        <section className='vowd'>
            <img 
                className='vowd__img' 
                src={people2} 
                width='1500px' 
                height='1000px'
                loading='lazy'
                alt='people' 
                onMouseEnter={onEnter2}
            />
            <div 
                id='vowd2__filtre' 
                className='vowd__filtre'
                onMouseLeave={onLeave2}
            >
                <p className='vowd__filtre__text'>Des prestations haut de gamme à la portée de toutes les bourses</p>
            </div>
        </section>
    )
}

export default Vowd2