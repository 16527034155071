import keyboard from '../../assets/pictures/keyboard.jpg'

// styles
import './m-background.css'
import './d-background.css'

const Background = () => {

	return (
		<div className='background'>
			<img 
				className='keyboard' 
				src={keyboard} 
				width='2339px' 
				height='1663px' 
				fetchpriority='high' 
				alt='keyboard' 
			/>
		</div>
	)
}
 
export default Background