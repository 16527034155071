import boule from '../../assets/pictures/boule.png'

// styles
import './m-pubwarning.css' 

const pubWarning = () => {
  return (
    <div className='pub-warning'>
        <img 
          className='pub-warning-boule' 
          src={boule} 
          width='490px' 
          height='587px' 
          loading='lazy' 
          alt="boule de sapin" 
        />
        <h2 className='pub-warning-subtitle'>-30%</h2>
    </div>
  )
}

export default pubWarning