import pagesHeadersArray from '../../datas/pagesHeadersArray.json'
import PagesHeaders from '../../components/PagesHeaders'
import elTaros from '../../assets/pictures/el-taros.jpg'
import turbo from '../../assets/pictures/auto-ecole-turbo.jpg'
import vowd from '../../assets/pictures/vowd-logo.jpg'
import mutuact from '../../assets/pictures/mutuact-logo.jpg'
import twobr from '../../assets/pictures/2br-logo.jpg'
import bcra from '../../assets/pictures/bcra-logo.jpg'
import leon from '../../assets/pictures/leon-logo.jpg'
import pant from '../../assets/pictures/pant-logo.jpg'
import kasa from '../../assets/pictures/kasa.jpg'
import kanap from '../../assets/pictures/kanap.png'
import omyfood from '../../assets/pictures/omyfood.jpg'
import booki from '../../assets/pictures/booki.jpg'
import { faGears, faPersonDigging } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// styles
import './m-portfolio.css'
import './d-portfolio.css'

const Portfolio = () => {

    const portfolioHeader = pagesHeadersArray.find(el => el.title === "5")
    const portfolioHeaderArray = []
    portfolioHeaderArray.push(portfolioHeader)

    const portfolioList = [
        {
            "cover": `${vowd}`,
            "title1": 'vowd.fr',
            "title2": 'site web',
            "designation": 'Concepteur web',
            "projet": 'Stack MERN',
            "url": 'https://www.vowd.fr/',
            "working": null,
            "width": "608px",
            "height": "494px"
        },
        {
            "cover": `${mutuact}`,
            "title1": 'Assurance',
            "title2": 'Mutuact',
            "designation": 'Assureur',
            "projet": 'Stack MERN',
            "url": 'https://www.mutuact.fr',
            "working": null,
            "width": "608px",
            "height": "494px"
        },
        {
            "cover": `${twobr}`,
            "title1": '2B',
            "title2": 'Réalisation',
            "designation": 'BTP (groupe BCRA)',
            "projet": 'Stack MERN',
            "url": 'https://www.2brealisation.com/2br',
            "working": null,
            "width": "608px",
            "height": "494px"
        },
        {
            "cover": `${bcra}`,
            "title1": 'Groupe',
            "title2": 'BCRA',
            "designation": 'Société BTP',
            "projet": 'Stack MERN',
            "url": 'https://www.bcra-groupe.com/',
            "working": null,
            "width": "608px",
            "height": "494px"
        },
        {
            "cover": `${turbo}`,
            "title1": 'Auto-école',
            "title2": 'Turbo',
            "designation": 'Auto-école',
            "projet": 'APP PHP MYSQL',
            "url": 'http://autoecole-turbo.com/',
            "working": null,
            "width": "882px",
            "height": "713px"
        },
        {
            "cover": `${leon}`,
            "title1": 'Léon',
            "title2": 'Showman',
            "designation": 'Artiste chanteur',
            "projet": 'Stack MERN',
            "url": 'https://divhunter.github.io/leon/',
            "working": true,
            "width": "608px",
            "height": "494px"
        },
        {
            "cover": `${omyfood}`,
            "title1": 'Omyfood',
            "title2": 'online',
            "designation": 'Restaurant en ligne',
            "projet": 'HTML & animations CSS',
            "url": 'https://divhunter.github.io/omyfood/',
            "working": null,
            "width": "882px",
            "height": "713px"
        },
        {
            "cover": `${kanap}`,
            "title1": 'Kanap',
            "title2": 'site web',
            "designation": 'Site e-commerce',
            "projet": 'Javascript dynamique',
            "url": 'https://divhunter.github.io/kanap/',
            "working": null,
            "width": "608px",
            "height": "491px"
        },
        {
            "cover": `${kasa}`,
            "title1": 'Kasa',
            "title2": 'site web',
            "designation": 'Location immobilière',
            "projet": 'APP REACT JS',
            "url": 'https://divhunter.github.io/kasa/',
            "working": null,
            "width": "608px",
            "height": "493px"
        },
        {
            "cover": `${pant}`,
            "title1": 'Agence',
            "title2": 'La panthère',
            "designation": 'Agence de com.',
            "projet": 'SEO, accéssibilité, design responsive',
            "url": 'https://divhunter.github.io/panthere/',
            "working": null,
            "width": "363px",
            "height": "295px"
        },
        {
            "cover": `${booki}`,
            "title1": 'Booki',
            "title2": 'Website',
            "designation": 'Hébergement pap',
            "projet": 'Intégration, design responsive',
            "url": 'https://divhunter.github.io/booki/',
            "working": null,
            "width": "608px",
            "height": "494px"
        },
        {
            "cover": `${elTaros}`,
            "title1": 'El',
            "title2": 'Taros',
            "designation": 'Site restaurateur',
            "projet": 'APP MERN',
            "url": '',
            "working": true,
            "width": "882px",
            "height": "713px"
        }
    ]

	return (
		<section id='portfolio' className='portfolio'>
            {portfolioHeaderArray.map((item, index) => (
                <PagesHeaders 
                    key={index}
                    titleCol1={item.titleCol1}
                    titleCol2={item.titleCol2}
                    subTitle1={item.subTitle1}
                    subTitle2={item.subTitle2}
                    text1={item.text1}
                    br1={item.br1 && <><br/><br/></>}
                    text2={item.text2}
                    icon1={
                        item.icon1 && 
                        <FontAwesomeIcon
                        className='portfolio__icon' 
                        icon={faGears} 
                        />
                    }
                />
            ))}
            <div className='portfolio__book'>
                {portfolioList.map((item, index) => (
                    <div key={index} className='portfolio__book__link' onClick={() => window.location=`${item.url}`}>
                        <h2 className='portfolio__book__link__title portfolio__book__link__title1'>{item.title2}</h2>
                        <h2 className='portfolio__book__link__title portfolio__book__link__title2'>{item.title1}</h2>
                        <article className='portfolio__book__link__article'>
                            <div className='portfolio__book__link__article__info'>
                                <h3>{item.designation}</h3>
                                <p>{item.projet}</p>
                            </div>
                            <img 
                                className='portfolio__book__link__article__cover'
                                src={item.cover}
                                width={item.width}
                                height={item.height}
                                loading='lazy' 
                                alt='logo-vowd'
                            />
                        </article>
                        {item.working ? 
                            <p className='portfolio__book__link__working'>
                                <FontAwesomeIcon
                                className='portfolio__book__link__working__icon' 
                                icon={faPersonDigging} 
                                /> En cours de réalisation
                            </p> 
                        : null}
                    </div>
                ))}
            </div>
		</section>
	)
}
 
export default Portfolio