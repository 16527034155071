import vowd from '../../assets/brands/logo-vowd.png'
import footerPeople from '../../assets/pictures/footer-people-m1.png'
import footerPeople2 from '../../assets/pictures/footer-people.png'
import {fab, faFacebook, faInstagram, faLinkedin, faGithub, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faPhone, faLocationDot, faAt, faGlobe } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// styles
import './m-footer.css'
import './t-footer.css'
import './d-footer.css'

const Footer = () => {

	return (
		<footer className='footer'>
			<h2 className='footer__fellow-me'>
				<span>Suivez
				</span> Moi...
			</h2>
			<div className='footer__link'>
                <FontAwesomeIcon 
                    className='footer__link__icon icon-fb' 
                    icon={(fab, faFacebook)} 
                    onClick={() => window.location='https://www.facebook.com/vowd.fr/'}
                />
                <FontAwesomeIcon 
                    className='footer__link__icon icon-in' 
                    icon={(fab, faInstagram)} 
                    onClick={() => window.location='https://www.instagram.com/vowd_france/'}
                />
                <FontAwesomeIcon 
                    className='footer__link__icon icon-li' 
                    icon={(fab, faLinkedin)} 
                    onClick={() => window.location='https://fr.linkedin.com/in/aaron-divioka-vowd-56258248'}
                />
                <FontAwesomeIcon 
                    className='footer__link__icon icon-gh' 
                    icon={(fab, faGithub)} 
                    onClick={() => window.location='https://github.com/Divhunter/'}
                />
                <FontAwesomeIcon 
                    className='footer__link__icon icon-yt' 
                    icon={(fab, faYoutube)} 
                    onClick={() => window.location=''}
                />
			</div>
            <img className='footer__logo-vowd' 
                src={vowd}
                width='1096px' 
				height='984px'
				loading='lazy' 
                alt='logo-vowd' 
            />
			<p className='footer__website'>
                <span lang="en">
                    <strong>
                        vowd.fr
                    </strong>
                </span>
                <br/>
                conception web
				<br/>
				& communication digitale
                <br/>
                siret: 94994281700014
                <br/>
			</p>
			<div className='footer__container-info'>
                <img 
                    className='picture-people' 
                    src={footerPeople} 
                    width='401px' 
                    height='300px'
                    loading='lazy' 
                    alt='people' 
                />
                <img 
                    className='picture-people2' 
                    src={footerPeople2} 
                    width='1400px' 
                    height='300px'
                    fetchpriority='high'  
                    alt='people' 
                />
                <div className='footer__container-info__content'>
                    <div className='footer__container-info__li'>
                        <FontAwesomeIcon
                            className='footer__container-info__li__icon-info' 
                            icon={faLocationDot} 
                        />
                        <p className='footer__container-info__li__text'>
                            7 cours Albert Thomas
                            <br/>
                            69003 Lyon - France
                        </p>
                    </div>
                    <div className='footer__container-info__li'>
                        <FontAwesomeIcon
                            className='footer__container-info__li__icon-info' 
                            icon={faAt} 
                        />
                        <p  className='footer__container-info__li__text'>
                            <a 
                                className='footer__container-info__li__text__link'
                                href='mailto:vowd-sv@outlook.com' 
                            >
                                vowd-sv@outlook.com
                            </a>
                            <br/>
                            <a
                                className='footer__container-info__li__text__link'
                                href='mailto:aarondivi@gmail.com' 
                            >
                                aarondivi@gmail.com
                            </a>
                        </p>
                    </div>
                    <div className='footer__container-info__li'>
                        <FontAwesomeIcon
                            className='footer__container-info__li__icon-info' 
                            icon={faPhone} 
                        />
                        <p className='footer__container-info__li__text'>
                            (+33) 427 115 913
                            <br/>
                            (+33) 635 774 835
                        </p>
                    </div>
                    <div className='footer__container-info__li'>
                        <FontAwesomeIcon
                            className='footer__container-info__li__icon-info' 
                            icon={faGlobe} 
                        />
                        <p className='footer__container-info__li__text'>
                            https://www.vowd.fr/
                            <br/>
                            https://www.facebook.com/vowd.fr/
                        </p>
                    </div>
                </div>
            </div>
            <div className='footer__copyright'>
                <p>
                    © 2023 - conception web par vowd.fr
                </p>
            </div>	
		</footer>
	)
}
 
export default Footer